import { useQuery } from "react-query";
import { getDetailPelanggan } from "../apis";

export const useGetDetailPelanggan = (payload, options = {}) => {
    const res = useQuery(["getDetailPelanggan", payload], getDetailPelanggan, {
        keepPreviousData: false,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        refetchOnReconnect: false,
        retry: false,
        cacheTime: 0,   // Disable cache storage
        staleTime: 0,   // Data is stale immediately after fetching
        ...options
      });
      res.data = res?.data?.data?.data ?? {};
      return res;
}