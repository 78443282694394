import Content from '@/components/specific/Content'
import { useDocumentTitle } from '@/utils/hooks/useDocumentTitle'
import React from 'react'

function Detail() {
  useDocumentTitle('Detail Tagihan Bulanan')
  const contentHeader = {
    title: 'Detail Tagihan Bulanan',
    breadcrumbs: [
        {
            name: 'Home',
            link: '/'
        },
        // {
        //     name: 'Info Pelanggan',
        //     link: '/#'
        // },
        {
            name: 'Info Tagihan',
            link: '/info-tagihan'
        },
    ]
  }
  return (
    <Content contentHeader={contentHeader} >
        <div className="flex gap-3 flex-col md:flex-row">
            <div className="w-full md:w-9/12 flex flex-col gap-5">
                {/* Data Pelanggann */}
                <div className="w-full prose max-w-none">
                    <h3>Informasi Pelanggan</h3>

                    <div className="w-full flex flex-col md:flex-row border-t border-base-400 py-2">
                        <div className="md:w-4/12">
                            <span className="font-medium text-base-content">Kode Pelanggan</span>
                        </div>
                        <div className="flex-grow">
                            <span className="font-normal text-base-content">100000000293828</span>
                        </div>
                    </div>
                    <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                        <div className="md:w-4/12">
                            <span className="font-medium text-base-content">Nama Pelanggan</span>
                        </div>
                        <div className="flex-grow">
                            <span className="font-normal text-base-content">Jhon Dhoe Sujondol</span>
                        </div>
                    </div>
                    <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                        <div className="md:w-4/12">
                            <span className="font-medium text-base-content">Alamat</span>
                        </div>
                        <div className="flex-grow">
                            <span className="font-normal text-base-content">Kantor PDAM Cilegon Mandi</span>
                        </div>
                    </div>
                    <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                        <div className="md:w-4/12">
                            <span className="font-medium text-base-content">Golongan</span>
                        </div>
                        <div className="flex-grow">
                            <span className="font-normal text-base-content">Rumah Tangga D</span>
                        </div>
                    </div>
                </div>

                {/* Periode Tagihan */}
                <div className="w-full prose max-w-none">
                    <div className="flex items-center gap-5 mb-4">
                        <h3 className='m-0'>Periode Tagihan</h3>
                        <button className="btn btn-base-100 btn-sm">
                            Agustus - 2024
                        </button>
                    </div>

                    <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                        <div className="md:w-4/12">
                            <span className="font-medium text-base-content">Stan Bulan Lalu</span>
                        </div>
                        <div className="flex-grow">
                            <span className="font-normal text-base-content font-medium"><b>3230</b></span>
                        </div>
                    </div>
                    <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                        <div className="md:w-4/12">
                            <span className="font-medium text-base-content">Stan Bulan Sekarang</span>
                        </div>
                        <div className="flex-grow">
                            <span className="font-normal text-base-content font-medium"><b>2800</b></span>
                        </div>
                    </div>
                    <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                        <div className="md:w-4/12">
                            <span className="font-medium text-base-content">Pakai (m)<sup>2</sup></span>
                        </div>
                        <div className="flex-grow">
                            <span className="font-normal text-base-content"><b>120m <sup>2</sup></b></span>
                        </div>
                    </div>
                </div>

                {/* Perhitungan Rekening */}
                <div className="w-full prose max-w-none">
                    <h3>Perhitungan Rekening</h3>

                    <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                        <div className="md:w-4/12">
                            <span className="font-medium text-base-content">Biaya Pemakaiann</span>
                        </div>
                        <div className="flex-grow">
                            <span className="font-normal text-base-content">Rp. 850.000</span>
                        </div>
                    </div>
                    <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                        <div className="md:w-4/12">
                            <span className="font-medium text-base-content">Biaya Admin</span>
                        </div>
                        <div className="flex-grow">
                            <span className="font-normal text-base-content">Rp. 2000</span>
                        </div>
                    </div>
                    <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                        <div className="md:w-4/12">
                            <span className="font-medium text-base-content">Dana Meter</span>
                        </div>
                        <div className="flex-grow">
                            <span className="font-normal text-base-content">Rp. 5000</span>
                        </div>
                    </div>
                    <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                        <div className="md:w-4/12">
                            <span className="font-medium text-base-content">Denda</span>
                        </div>
                        <div className="flex-grow">
                            <span className="font-normal text-base-content">Rp. 0</span>
                        </div>
                    </div>
                    <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                        <div className="md:w-4/12">
                            <span className="font-medium text-base-content">Status Pembayaran</span>
                        </div>
                        <div className="flex-grow">
                            <div className="badge badge-success text-white">
                                Lunas
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                        <div className="md:w-4/12">
                            <span className="font-medium text-base-content">Total Tagihann</span>
                        </div>
                        <div className="flex-grow">
                            <span className="font-normal text-base-content"><b>Rp. 850.000</b></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-grow">
                <div className="w-full prose max-w-none">
                    <h3>Foto Meter</h3>
                    <div className="w-full h-[270px] md:h-[500px]">
                        <img 
                            src="https://www.ferindo.id/uploads/image/Instalasi_Meteran_Air.jpg"
                            alt="Foto Meter" 
                            className="w-full h-full object-fit cursor-zoom-in m-0" 
                        />
                    </div>
                </div>
            </div>
        </div>
    </Content>
  )
}

export default Detail