import { MyInput } from '@/components/common/Form'
import Content from '@/components/specific/Content'
import { useDocumentTitle } from '@/utils/hooks/useDocumentTitle'
import { useGetDetailPelanggan } from '@/utils/hooks/useFetchWrapper'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function Tagihan() {
  const contentHeader = {
    title: 'Info Tagihan',
    breadcrumbs: [
        {
            name: 'Home',
            link: '/#'
        },
        {
            name: 'Info Pelanggan',
            link: '/#'
        },
    ]
  }

  const [payload, setPayload] = useState(null)
  const [pelanggan, setPelanggan] = useState(null)

  const {data, refetch, isFetching,isError, error} = useGetDetailPelanggan({id: payload, status: 'paid'}, {enabled: false, refetchOnMount: false})
  useEffect(() => {
    if (data.pelanggan) {
        setPelanggan(data?.pelanggan ?? null)
    }
  }, [data])

  return (
    <Content contentHeader={contentHeader} >
        <div className="md:w-2/6 flex gap-2" data-theme="mytheme">
            <MyInput 
                type="number" 
                placeholder="Masukan Nomor Sambungan"
                defaultValue={payload}
                onChange={(e) => setPayload(e.target.value)}
            />
            <button 
                className="btn bg-primary text-white hover:bg-primary"
                onClick={() => {
                    setPelanggan(null)
                    refetch()
                }}
            >
                Cari
            </button>
        </div>

        {/* Data Pelanggan */}
        {
            (pelanggan) &&
            (
                <div className="w-full">
                    <h3>Data Pelanggan</h3>

                    <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                        <div className="w-full md:w-4/12">
                            <span className="font-medium text-base-content">Nomor Sambungan</span>
                        </div>
                        <div className="flex-grow">
                            <span className="font-normal text-base-content">{pelanggan.no_meter}</span>
                        </div>
                    </div>
                    <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                        <div className="w-full md:w-4/12">
                            <span className="font-medium text-base-content">Nama Pelanggan</span>
                        </div>
                        <div className="flex-grow">
                            <span className="font-normal text-base-content">{pelanggan.nama}</span>
                        </div>
                    </div>
                    <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                        <div className="w-full md:w-4/12">
                            <span className="font-medium text-base-content">Alamat</span>
                        </div>
                        <div className="flex-grow">
                            <span className="font-normal text-base-content">{pelanggan.alamat}</span>
                        </div>
                    </div>
                    <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                        <div className="w-full md:w-4/12">
                            <span className="font-medium text-base-content">Total Tagihan</span>
                        </div>
                        <div className="flex-grow">
                            <span className="font-normal text-base-content">{1010101010}</span>
                        </div>
                    </div>
                    <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                        <div className="w-full md:w-4/12">
                            <span className="font-medium text-base-content">Golongan</span>
                        </div>
                        <div className="flex-grow">
                            <span className="font-normal text-base-content">Rumah Tangga {pelanggan.golongan}</span>
                        </div>
                    </div>
                </div>
            )
        }

        {/* Periode Tahun */}
        {
            (pelanggan) &&
            (
                <div className="w-full">
                    <h3>Periode Tahun</h3>

                    {
                        [0,0,0].map((data, idx) => (
                            <div key={idx} className="collapse collapse-arrow bg-base-200 mt-2">
                                <input type="radio" name="my-accordion-2" defaultChecked={idx === 0} />
                                <div className="collapse-title text-xl font-medium h-[20px]">2024</div>
                                <div className="collapse-content mt-2 bg-base-100">
                                    <div className="overflow-x-auto">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Periode</th>
                                                    <th>Total</th>
                                                    <th>Status</th>
                                                    <th>Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    [0,0,0].map((data, idx) => (
                                                        <tr key={idx}>
                                                            <td>Agustus 2024</td>
                                                            <td>Rp. 250.000</td>
                                                            <td>
                                                                <div className="badge badge-success text-white">
                                                                    Lunas
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <Link to="/info-tagihan/123" className="btn btn-info text-white btn-xs">
                                                                    Tagihan
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            )
        }

        {
            isFetching &&
            (
                <div className="w-full flex flex-col gap-5">
                    <div className="skeleton h-4 w-full"></div>
                    <div className="skeleton h-4 w-10/12"></div>
                    <div className="skeleton h-4 w-9/12"></div>
                    <div className="skeleton h-32 w-4/12"></div>
                </div>
            )
        }

        {
            (isError) &&
            (
                <div 
                    className="
                    w-full h-60 bg-base-200 rounded-md flex flex-col 
                    items-center justify-center gap-5
                    "
                >
                    <span className="text-red-500 font-normal">
                        {error?.response?.data?.message ?? error?.data?.message ?? 'Error'}
                    </span>
                    <button onClick={refetch} className="btn btn-sm btn-primary text-white">Coba Lagi</button>
                </div>
            )
        }
    </Content>
  )
}

export default Tagihan