import React from "react";
import { RouterProvider } from "react-router-dom";
import { routerApp } from "./router";
import { QueryClient, QueryClientProvider } from "react-query";
import { AnimatePresence } from "framer-motion";

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <AnimatePresence>
        <RouterProvider router={routerApp}/>
      </AnimatePresence>
    </QueryClientProvider>
  );
}

export default App;
