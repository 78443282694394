import React from 'react'

function Container({children, className = '', ...props}) {
  return (
    <div className={`w-full md:min-w-[1200px] md:max-w-[1200px] md:mx-auto flex flex-col ${className}`} {...props} >
        {children}
    </div>
  )
}

export default Container